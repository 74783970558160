<template>
    <div class="tagList">
        <HeadBar :titleName="tagName"></HeadBar>
        <div class="mian">
            <PullRefresh :loading="loading" :refreshing="refreshing" :finished="finished" @onLoad="onLoad"
                @onRefresh="onRefresh" :isHigehtMax="true" :isNoData="isNoData" :error="error">
                <VerticleThreeList :videoList="list" v-if="kind == 0 || kind == 1 || kind == 5 || kind == 4">
                </VerticleThreeList>
                <TransverselyTwoList :videoList="list" v-else></TransverselyTwoList>
            </PullRefresh>
        </div>
    </div>
</template>

<script>
    import HeadBar from "@/components/HeadBar";
    import PullRefresh from "@/components/PullRefresh"
    import VerticleThreeList from "@/components/VideoList/VerticleThreeList";
    import TransverselyTwoList from "@/components/VideoList/TransverselyTwoList";
    import {
        queryTagsmediaList,
    } from "@/api/video"
    export default {
        name: 'tagList',
        components: {
            HeadBar,
            PullRefresh,
            VerticleThreeList,
            TransverselyTwoList,
        },
        watch: {
            $route(to, from) {
                if (to.path == from.path) {
                    this.kind = Number(this.$route.query.kind);
                    let dataStr = this.$route.query.tagData;
                    let decodeData = window.atob(decodeURI(dataStr));
                    let tagData = JSON.parse(decodeURI(window.decodeURI(decodeData)));
                    this.tagId = tagData.id;
                    this.tagName = this.handleKindName() + " " + tagData.name;
                    this.list = [];
                    this.pageNumber = 1;
                    this.refreshing = true;
                    this.finished = false;
                    this.loading = true;
                    this.getList("refresh"); 
                }
            },
        },
        data() {
            return {
                navTitle: "",
                tagId: "",
                tagName: "",
                kind: "",
                list: [],
                pageNumber: 1,
                pageSize: 10,
                loading: true,
                refreshing: false,
                finished: false,
                error: false,
                isNoData: false,
            }
        },
        created() {
            this.kind = Number(this.$route.query.kind);
            let dataStr = this.$route.query.tagData;
            let decodeData = window.atob(decodeURI(dataStr));
            let tagData = JSON.parse(decodeURI(window.decodeURI(decodeData)));
            this.tagId = tagData.id;
            // this.list = [];
            // console.log(tagData.name)
            this.getList();
            this.tagName = this.handleKindName() + " " + tagData.name;
        },
        methods: {
            handleKindName() {
                let str = "";
                switch (this.kind) {
                    case 0:
                        str = "动漫"
                        break;
                    case 1:
                        str = "漫画"
                        break;
                    case 3:
                        str = "真人"
                        break;
                    case 5:
                        str = "写真"
                        break;
                    case 4:
                        str = "小说"
                        break;
                    case 6:
                        str = "原作"
                        break;
                    default:
                        break;
                }
                return str;
            },
            // 查询列表
            async getList(type) {
                let req = {
                    pageNumber: this.pageNumber,
                    pageSize: this.pageSize,
                    kind: this.kind,
                    tags: this.tagId
                    // uid: 224411
                }
                try {
                    let res = await this.$Api(queryTagsmediaList, req);
                    this.loading = false;
                    this.refreshing = false;
                    if (res.code === 200) {
                        let list = res.data.MediaList || [];
                        if (type === 'refresh') {
                            this.list = list;
                        } else {
                            this.list = this.list.concat(list);
                        }
                        if (this.list.length <= 0) {
                            this.isNoData = true
                        }
                        if (!res.data.hasNext) {
                            this.finished = true;
                            return;
                        }
                    } else {
                        this.error = true;
                    }
                } catch (error) {
                    this.error = true;
                    this.loading = false;
                }


            },
            // 上啦加载
            onLoad() {
                this.loading = true;
                if (this.error) {
                    this.error = false;
                } else {
                    this.pageNumber++
                }
                this.getList();
            },
            // 下拉刷新
            onRefresh() {
                this.pageNumber = 1;
                this.refreshing = true;
                this.finished = false;
                this.loading = true;
                this.getList("refresh");
            },
        }
    }
</script>

<style scoped lang="scss">
    .tagList {
        height: 100%;

        .mian {
            height: calc(100% - 45px);
            padding: 0 12px;
            box-sizing: border-box;
        }
    }
</style>